import styled from 'styled-components';
import { colors } from '../../../styles/atoms/colors';

export const Container = styled.div`
  display: flex;
  gap: 24px;
  .prev,
  .next {
    width: 48px;
    height: 48px;
    background: ${colors.base.white};
    border: 1px solid ${colors.gray[200]};
    backdrop-filter: blur(8px);
    border-radius: 28px;
    opacity: 1;
    transition: all 250ms ease-in-out;
    svg {
      color: ${colors.gray[500]};
      transition: all 250ms ease-in-out;
    }
    &:hover {
      background: ${colors.base.light};
      border: 1px solid ${colors.gray[200]};
      svg {
        color: ${colors.gray[300]};
      }
    }
  }
`;
