import React from 'react';

import Icon from '../../../styles/atoms/icons';

import { Container } from '../styles/VideoCarouselControls.styled';

const VideoCarouselControls = ({ sliderRef }) => (
  <Container className="controls">
    <button
      type="button"
      aria-label="Previous"
      className="prev"
      onClick={() => sliderRef.current.slickPrev()}
    >
      <Icon id="FiArrowLeft" size="20px" />
    </button>
    <button
      type="button"
      aria-label="Next"
      className="next"
      onClick={() => sliderRef.current.slickNext()}
    >
      <Icon id="FiArrowRight" size="20px" />
    </button>
  </Container>
);

export default VideoCarouselControls;
